import { PostNordReducer } from '../store/PostNord/PostNord.reducer';

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    PostNordReducer
});

export const config = {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};

export default config;
