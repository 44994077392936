import { UPDATE_COUNT_DOWN_SALE_CONFIG } from '@bodymod/sale-countdown/src/store/SaleCountdown/SaleCountdown.action';

import { getInitialState } from 'Store/Config/Config.reducer';

const ConfigReducer = (args, callback, instance) => {
    const [state = getInitialState(), action] = args;
    const originalUpdatedState = callback.apply(instance, args);

    if (!action) {
        return originalUpdatedState;
    }

    const {
        type
    } = action;

    switch (type) {
    case UPDATE_COUNT_DOWN_SALE_CONFIG:
        const { payload } = action;
        return {
            ...state,
            ...payload
        };

    default:
        return originalUpdatedState;
    }
};

export default {
    'Store/Config/Reducer/ConfigReducer': {
        function: ConfigReducer
    }
};
