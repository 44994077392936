import { Field } from 'Util/Query';

const _getStoreConfigFields = (args, callback) => [
    ...callback(...args),
    'campaign_countdown_start_date',
    'campaign_countdown_end_date',
    'campaign_countdown_gtm_adjustment'
];
const getSaleCountdownQuery = () => new Field('storeConfig')
    .addFieldList([
        'campaign_countdown_start_date',
        'campaign_countdown_end_date',
        'campaign_countdown_gtm_adjustment'
    ]);

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields,
            getSaleCountdownQuery
        }
    }
};
