import { PacketaReducer } from '../store/Packeta/Packeta.reducer';

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    PacketaReducer
});

export const config = {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};

export default config;
