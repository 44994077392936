export const MAX_ADITIONAL_FILTERS = 3;

export const LOADING_TIME = 500;

export const DISPLAY_MODE_PRODUCTS = 'PRODUCTS';

export const DISPLAY_MODE_CMS_BLOCK = 'PAGE';

export const DISPLAY_MODE_BOTH = 'PRODUCTS_AND_PAGE';

export const LAYOUT_KEY = 'layout';
export const GRID_LAYOUT = 'grid';
export const LIST_LAYOUT = 'list';

export const SORT_DIRECTION_TYPE = {
    asc: 'ASC',
    desc: 'DESC'
};
