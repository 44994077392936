import {
    TYPE_CATEGORY as SOURCE_TYPE_CATEGORY,
    TYPE_CMS_PAGE as SOURCE_TYPE_CMS_PAGE,
    TYPE_NOTFOUND as SOURCE_TYPE_NOTFOUND,
    TYPE_PRODUCT as SOURCE_TYPE_PRODUCT
} from 'SourceRoute/UrlRewrites/UrlRewrites.config';

export const TYPE_BLOG_CATEGORY = 'BLOG_CATEGORY';
export const TYPE_BLOG_TAG = 'BLOG_TAG';
export const TYPE_BLOG_POST = 'BLOG_POST';
export const TYPE_BLOG_HOMEPAGE = 'BLOG_HOMEPAGE';

export const BLOG_TYPE = {
    [TYPE_BLOG_CATEGORY]: 'CATEGORY',
    [TYPE_BLOG_TAG]: 'TAG',
    [TYPE_BLOG_POST]: 'POST',
    [TYPE_BLOG_HOMEPAGE]: 'HOMEPAGE'
};
// TODO: implement TYPE_PRODUCT
export const TYPE_PRODUCT = SOURCE_TYPE_PRODUCT;

// TODO: implement TYPE_CMS_PAGE
export const TYPE_CMS_PAGE = SOURCE_TYPE_CMS_PAGE;

// TODO: implement TYPE_CATEGORY
export const TYPE_CATEGORY = SOURCE_TYPE_CATEGORY;

// TODO: implement TYPE_NOTFOUND
export const TYPE_NOTFOUND = SOURCE_TYPE_NOTFOUND;
