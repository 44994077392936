import { PACKETA_METHOD_TYPE } from '../component/Packeta/Packeta.config';

export const renderActions = (args, callback, instance) => {
    const {
        selectedShippingMethod: { available, shipping_method_type_detail: type } = {}, packetaPoint: { name } = {}
    } = instance.props;

    const isPacheta = PACKETA_METHOD_TYPE === type;
    if (isPacheta) {
        const disablePacheta = available && isPacheta && !name;
        return (
            <div block="Checkout" elem="StickyButtonWrapper">
                <button
                  type="submit"
                  block="Button"
                  disabled={ disablePacheta || !available }
                  mix={ { block: 'CheckoutShipping', elem: 'Button' } }
                >
                    { __('Proceed to billing') }
                </button>
            </div>
        );
    }

    return callback(...args);
};
const mapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        packetaPoint: state.PacketaReducer.point
    };
};

export const containerProps = (args, callback, instance) => {
    const {
        packetaPoint
    } = instance.props;

    return {
        ...callback(...args),
        packetaPoint
    };
};

export default {
    'Component/CheckoutShipping/Component': {
        'member-function': {
            renderActions
        }
    },
    'Component/CheckoutShipping/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            containerProps
        }
    }
};
