import { updateCountDownSaleConfig as updateConfig } from
'@bodymod/sale-countdown/src/store/SaleCountdown/SaleCountdown.action';

const updateCountDownSaleConfig = (args) => {
    const [dispatch, data] = args;
    dispatch(updateConfig(data));
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            updateCountDownSaleConfig
        }
    }
};
