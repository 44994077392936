import { lazyComponentLoader } from 'Util/lazyComponentLoader';

import { POST_NORD_METHOD_TYPE } from '../component/PostNord/PostNord.config';

export const PostNordContainer = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout" */ '../component/PostNord/PostNord.container'
), 2);

export const renderPostNord = () => <PostNordContainer />;
const renderAditionalFields = (args, callback, instance) => {
    const { isSelected, option: { shipping_method_type_detail: type } } = instance.props;
    if (POST_NORD_METHOD_TYPE === type && isSelected) {
        return renderPostNord();
    }

    return callback(...args);
};

export default {
    'Component/CheckoutDeliveryOption/Component': {
        'member-function': {
            renderAditionalFields
        }
    }
};
