import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    SomethingWentWrongContainer as SourceSomethingWentWrongContainer
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.container';

import SomethingWentWrong from './SomethingWentWrong.component';
import { PAGE_HAS_BEEN_FORCE_REFRESHED } from './SomethingWentWrong.config';
/** @namespace Bodypwa/Route/SomethingWentWrong/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Bodypwa/Route/SomethingWentWrong/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device
});
/** @namespace Bodypwa/Route/SomethingWentWrong/Container */
export class SomethingWentWrongContainer extends SourceSomethingWentWrongContainer {
    componentDidMount() {
        const { updateMeta } = this.props;
        updateMeta({ title: __('Something went wrong!') });
        this.retryPageLoading();
    }

    state = {
        renderPage: false
    };

    retryPageLoading = () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(PAGE_HAS_BEEN_FORCE_REFRESHED) || 'false'
        );

        if (!pageHasAlreadyBeenForceRefreshed && process.env.NODE_ENV === 'production') {
            window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, 'true');
            this.logError({ refresh: true, logToLocalStorage: true });
            window.location.reload();
        } else {
            window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, 'false');
            this.setState({ renderPage: true });
        }
    };

    logError() {
        const { errorDetails } = this.props;

        Sentry.captureEvent({ message: `Oops page!: ${ errorDetails?.info}` });
        Sentry.captureException(errorDetails?.err);
    }

    render() {
        const { renderPage } = this.state;
        if (!renderPage) {
            return null;
        }

        return (
            <SomethingWentWrong
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SomethingWentWrongContainer);
