import {
    SomethingWentWrong as SourceSomethingWentWrong
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';
/** @namespace Bodypwa/Route/SomethingWentWrong/Component */
export class SomethingWentWrongComponent extends SourceSomethingWentWrong {
    renderErrorDetails() {
        const { errorDetails: { err, info: { componentStack } = {} } } = this.props;
        const errorString = err.toString();

        console.groupCollapsed('Suppressed error log:');
        console.error(errorString);
        console.groupEnd();
        if (process.env.NODE_ENV === 'production') {
            return null;
        }

        return (
            <div block="SomethingWentWrong" elem="Debug">
                { errorString }
                { componentStack }
            </div>
        );
    }
}

export default SomethingWentWrongComponent;
