/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

export const CountryType = PropTypes.shape({
    label: PropTypes.string,
    names: PropTypes.arrayOf(PropTypes.string),
    locale: PropTypes.string,
    code: PropTypes.string,
    url: PropTypes.string,
    currency: PropTypes.string,
    hreflang: PropTypes.string
});

export const StoreCountriesType = PropTypes.shape({
    names: PropTypes.arrayOf(PropTypes.string),
    code: PropTypes.string,
    url: PropTypes.string
});

export const HreflangsType = PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    url: PropTypes.string
}));
