import { lazyComponentLoader } from 'Util/lazyComponentLoader';

import { PACKETA_METHOD_TYPE } from '../component/Packeta/Packeta.config';

export const PacketaContainer = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout" */ '../component/Packeta/Packeta.container'
), 2);

export const renderPacketa = (shippingCountryId) => <PacketaContainer shippingCountryId={ shippingCountryId } />;

const renderAditionalFields = (args, callback, instance) => {
    const { isSelected, option: { shipping_method_type_detail: type }, shippingCountryId } = instance.props;
    if (PACKETA_METHOD_TYPE === type && isSelected) {
        return renderPacketa(shippingCountryId);
    }

    return callback(...args);
};

export default {
    'Component/CheckoutDeliveryOption/Component': {
        'member-function': {
            renderAditionalFields
        }
    }
};
