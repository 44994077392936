import { Field } from 'Util/Query';

const getSeoParams = () => [
    'meta_robots'
];

const getUrlData = () => [
    'code',
    'url'
];
const getUrlWithIdData = () => [
    'code',
    'url',
    'store_id'
];

const getRitchSnipetsData = (type = 'webpage', ...args) => [
    'seller',
    `${type}`,
    ...args,
    'website',
    'breadcrumbs',
    'organization'
];

const canonicalUrl = (args = []) => {
    const [isPdp] = args;
    if (!isPdp) {
        return;
    }

    // eslint-disable-next-line consistent-return
    return new Field('mw_canonical_url')
        .addFieldList(getUrlData());
};
const items = () => new Field('items')
    .addFieldList(getUrlWithIdData());

const hrefLang = (args = []) => {
    const [isPdp] = args;
    if (!isPdp) {
        return;
    }

    // eslint-disable-next-line consistent-return
    return new Field('mw_hreflangs')
        .addField(items());
};

const ritchSnippets = (type, ...args) => new Field('rich_snippets')
    .addFieldList(getRitchSnipetsData(type, ...args));

const seoMarkup = (type, ...args) => new Field('mw_seo_markup')
    .addField(ritchSnippets(type, ...args))
    .addField('social_markup');

const metaRobots = () => new Field('meta_robots');

export const getQuery = (args, callback) => callback(...args)
    .addFieldList(getSeoParams())
    .addField(canonicalUrl())
    .addField(hrefLang(args))
    .addField(seoMarkup())
    .addField(metaRobots());

export const _getItemsField = (args, callback) => callback(...args)
    .addField(hrefLang(args))
    .addField(canonicalUrl(args))
    .addField(metaRobots());

export const getCategoryQuery = (args, callback) => callback(...args)
    .addField(hrefLang(args))
    .addField(seoMarkup('seller', 'category'))
    .addField(canonicalUrl())
    .addField(metaRobots());

export default {
    'Query/CmsPage/Query': {
        'member-function': {
            getQuery
        }
    },
    'Query/ProductList/Query': {
        'member-function': {
            _getItemsField
        }
    },
    'Query/Category/Query': {
        'member-function': {
            getQuery: getCategoryQuery
        }
    }
};
