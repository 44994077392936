import { POST_NORD_METHOD_TYPE } from '../component/PostNord/PostNord.config';

export const renderActions = (args, callback, instance) => {
    const {
        selectedShippingMethod: { available, shipping_method_type_detail: type } = {}, postNordPoint: { name }
    } = instance.props;

    const isPostNord = POST_NORD_METHOD_TYPE === type;
    if (isPostNord) {
        const disablePostNord = available && isPostNord && !name;
        return (
            <div block="Checkout" elem="StickyButtonWrapper">
                <button
                  type="submit"
                  block="Button"
                  disabled={ disablePostNord || !available }
                  mix={ { block: 'CheckoutShipping', elem: 'Button' } }
                >
                    { __('Proceed to billing') }
                </button>
            </div>
        );
    }

    return callback(...args);
};
const mapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        postNordPoint: state.PostNordReducer.point
    };
};

export const containerProps = (args, callback, instance) => {
    const {
        postNordPoint
    } = instance.props;

    return {
        ...callback(...args),
        postNordPoint
    };
};

export default {
    'Component/CheckoutShipping/Component': {
        'member-function': {
            renderActions
        }
    },
    'Component/CheckoutShipping/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            containerProps
        }
    }
};
