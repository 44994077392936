import { lazyComponentLoader } from 'Util/lazyComponentLoader';

export const SaleCountdown = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "sale-countdown" */ '../component/SaleCountdown'
), 2);
export const renderSaleCountdown = (campaignLabel) => <SaleCountdown campaignLabel={ campaignLabel } />;
export class ProductActionsPlugin {
    renderAditionalInfo(args, callback, instance) {
        const { product: { campaign_label } = {} } = instance.props;
        return (
            <>
                { renderSaleCountdown(campaign_label) }
                { callback(...args) }
            </>
        );
    }
}
const { renderAditionalInfo } = new ProductActionsPlugin();
export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderAditionalInfo
        }
    }
};
